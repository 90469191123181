<template>
   <div v-if="userProfile.role!='Admin'">
    	<div class="list-high-roi">
			<!-- <p>  Portfolio Overview for {{currentUser.email.split('@')[0].charAt(0).toUpperCase() + currentUser.email.split('@')[0].slice(1)}} </p> -->
			<p>  Portfolio Overview for {{userProfile.name}} </p>
		 </div>
		
		
		<div class="two-divd-colored-wrap">
			<div class="two-divd-colored-left">
				<h3> Purchase List </h3>
				<template v-for="share in filteredShares">
          <recommendation-card
            v-bind:key="share.ticker"
            :share="share"
            v-if="share.trade_recommendation == 'Buy'"
          />
        </template>
					
		  </div>

      <div class="two-divd-colored-left">
				<h3> Hold/Sell List </h3>
				<template v-for="share in filteredSharesSale">
          <recommendation-card
            v-bind:key="share.ticker"
            :share="share"
            v-if="share.trade_recommendation == 'Sell'"
          />
        </template>
					
		  </div>
	 </div>
  </div>
  <div v-else>
	<div class="main-cover">
	 <div class="sidebar-wrap">

		<div class="side-details">
			<router-link to="/" exact class="logo" style="text-decoration:none;">
        <div class="logo"> Perquity </div>
        </router-link>
			<ul>
				<li v-for="navItem in navItems"
        :key="navItem.title" @click="navItem.onClick"> 
					<span> <img :src="navItem.icon"> </span>
					<span> {{ navItem.title }}</span>
				</li>
			</ul>
		</div>
	</div>
	<div class="main-content">
		<div class="top-header-section">
            <div class="th-left">
				
			</div>
       
			<div class="th-right"> 
				<div class="profile-menu">
					<div class="dropdown-container">
						<div class="dropdown-toggle click-dropdown">
						 <img src="images/profile.png">
						 
             <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <img src="images/arrow.svg" style="width:15px">
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(menuItem, i) in menuItems"
              :key="i"
              @click="menuItem.onClick"
            >
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
						</div>
						<!-- <div class="dropdown-menu">
							<ul>
								<li><a href="#">DropDown Menu Item 1</a></li>
								<li><a href="#">DropDown Menu Item 2</a></li>
								<li><a href="#">DropDown Menu Item 3</a></li>
								<li><a href="#">DropDown Menu Item 4</a></li>
							</ul>
						</div> -->
					</div>
				</div>
			</div>
		</div>


        <div class="th-left">
                  <div class="thead"> 
            Portfolio Overview for 
            {{userProfileSingle.name}} 
          </div>
                  
          </div>
          <div class="two-divd-colored-wrap">
			<div class="two-divd-colored-left">
				<h3> Purchase List </h3>
				<template v-for="share in filteredShares">
          <recommendation-card
            v-bind:key="share.ticker"
            :share="share"
            v-if="share.trade_recommendation == 'Buy'"
          />
        </template>
					
		 </div>

     <div class="two-divd-colored-left">
				<h3> Hold/Sell List </h3>
				<template v-for="share in filteredSharesSale">
          <recommendation-card
            v-bind:key="share.ticker"
            :share="share"
            v-if="share.trade_recommendation == 'Sell'"
          />
        </template>
					
		 </div>
	 </div>
	</div>
  </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RecommendationCard from '../components/RecommendationCard.vue'
import { auth } from '@/db'
import router from '../router'
export default {
  components: {
    RecommendationCard
  },
  data() {
    return {

      currentUser:auth.currentUser,
	  navItems: [
        {
          title: 'Portfolio',
          // iconC: PlusIcon,
          icon: 'images/sb1.png',
          onClick: () => {
            router.push({ name: 'Userportfolio' })
            //this.updateActiveDialog('addShare')
          }
          // img: './assets/logo.png'
        },
        {
          title: 'Buy / Sell Advice',
          icon: 'images/sb4.png',
          onClick: () => {
            router.push({ name: 'TradeAdvice' })
          }
          // img: './assets/Plus.svg'
        },
        {
          title: 'Exit',
          icon: 'images/sb4.png',
          onClick: () => {
            router.push({ name: 'Authguard' })
          }
          // img: './assets/Plus.svg'
        },
        {
          title: 'Execute',
          icon: 'images/sb4.png',
          onClick: () => {
            console.log("Need to set");
            router.push({ name: 'Usertables' })
          }
          // img: './assets/Plus.svg'
        },
      ],
	  
    }
  },
  computed: {
    ...mapState(['tradeAdvice', 'userProfile', 'userProfileSingle']),
    filteredShares: function () {
      console.log('Trade Advice Shares: ', this.tradeAdvice?.shares);
      let shares = JSON.parse(JSON.stringify(this.tradeAdvice?.shares));
      let onlybuy = [];
      for(let i=0;i<shares.length;i++){
        if(shares[i].purchase_serial != 0) {
          onlybuy.push(i);
         }
      }
      let checkObject = [];
      for(let i=0;i<shares.length;i++){
        checkObject.push(shares[i].share_category);
      }
      //console.log("XXXXXX======>",checkObject);
      const count = {};
      checkObject.forEach((element) => {
        count[element] = (count[element] || 0) + 1;
      });
      let counter = onlybuy.length+1;

      // 👇️ {one: 3, two: 2, three: 1}
      //console.log(count);
     // shares.sort((a,b)=>a.purchase_serial-b.purchase_serial)
      let newShares = shares.map((item)=>{
        //console.log("Category=======>", count[item.share_category]);
        if (count[item.share_category] == 1) {
          item.trade_recommendation = 'Buy';
          item.purchase_serial = counter++;
        }
        if (item.transactions) {
          return {...item, lastTransactionType:item?.transactions[item?.transactions?.length-1]?.transactionType};
        } else {
          return {...item, lastTransactionType:'Sell'};
        }
        
      });
      newShares = newShares.sort((a,b)=>a.priority_order_buy-b.priority_order_buy);
      let k=1;
      if (newShares) {
        for(let l=0;l<newShares?.length;l++) {
        if (newShares[l].trade_recommendation == 'Buy') {
          newShares[l] = {...newShares[l], purchase_serial:k};
          k++;
        }
      }
      }
      return newShares;
    },


    filteredSharesSale: function () {
      console.log('Trade Advice Shares: ', this.tradeAdvice?.shares);
      let shares = JSON.parse(JSON.stringify(this.tradeAdvice?.shares));
      //shares.sort((a,b)=>a.purchase_serial-b.purchase_serial)
      let onlysell = [];
      let checkObject = [];
      for(let i=0;i<shares.length;i++){
        checkObject.push(shares[i].share_category);
      }
      //console.log("XXXXXX======>",checkObject);
      const count = {};
      checkObject.forEach((element) => {
        count[element] = (count[element] || 0) + 1;
      });
      //shares = shares.reverse();
      shares = shares.map((item)=>{
        if (count[item.share_category] == 1) {
          item.trade_recommendation = 'Buy';
          item.purchase_serial = 1;
        }
        return {...item, saleshares:saleshares};
      });
      console.log("SHHHHHHHHH============>", shares);
      for(let i=0;i<shares.length;i++){
        if(shares[i].purchase_serial == 0) {
          onlysell.push(i);
         }
        //onlysell.push(item);
      }
      //priority_order_sell
      
      let saleshares = onlysell.length+1;
      shares = shares.map((item)=>{
        if(item.purchase_serial == 0) {
          saleshares--;
          console.log("Sales Share",saleshares);
          return {...item, saleshares:saleshares};
        } else {
          return {...item, saleshares:saleshares};
        }
      });
      shares = shares.sort((a,b)=>a.priority_order_sell-b.priority_order_sell);
      return shares;
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/variables.scss';
.ds-title {
  @include heading_2_regular;
  font-weight: 400;
  margin-bottom: 1.875rem;
  margin-top: 5.68rem;
}
.ds-subtitle {
  @include heading_2_regular;
  font-weight: 400;
  margin-bottom: 1.875rem;
}

.two-divd-colored-wrap {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-column-gap: 10%;
}

.dcb-wrap 
{
    width: 450px;
	  margin-bottom:20px;
    box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
}

.dcb-wrap:last-child 
{
	margin-bottom:0;
}

.dcb-wrap .tradeAdviceGridUl 
{
    list-style-type: none;
    margin-top: 20px;
    display: flex;
    // justify-content: space-between;
}

.tradeAdvice-white-grid 
{
    list-style-type: none;
}

.tradeA-top {
    font-size: 10px;
    font-weight: 600;
    margin-left: 12px;
    width: 90%;
    text-transform: uppercase;
}

.mid-small-sepTA {
    display: flex;
    width: 22px;
    background-color: #67D6C4;
    height: 3px;
    margin-left: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.tradeA-bottom
{
	font-size: 15px;
    font-weight: 600;
    margin-left: 12px;
    width: 90%;
    text-transform: uppercase;
}

.flex-jc-wrapTA
{
    display: flex;
    justify-content: space-between;
	padding: 25px 10px;
    grid-column-gap: 20px;
}

.ta-lightPink 
{
    background-color: #FFF1F6;
}

.ta-lightPink li:first-child 
{
	color:#fff;
	font-weight:bold;
    background-color: #FF9BBD;
    padding: 10px 20px;
}

.ta-lightYellow 
{
    background-color: #FEFAEC;
}

.ta-lightYellow li:first-child 
{
	color:#fff;
	font-weight:bold;
    background-color: #F4D577;
    padding: 10px 20px;
}

.ta-lightBlue 
{
    background-color: #F1F6FF;
}

.ta-lightBlue li:first-child 
{
	color:#fff;
	font-weight:bold;
    background-color: #90B5FF;
    padding: 10px 20px;
}
.ta-lightGreen 
{
    background-color: #c6f0e9;
}

.ta-lightGreen li:first-child 
{
	color:#fff;
	font-weight:bold;
    background-color: #7adbcb;
    padding: 10px 20px;
}

.ta-lightOrg 
{
    background-color: #FAEAE6;
}

.ta-lightOrg li:first-child 
{
	color:#fff;
	font-weight:bold;
    background-color: #EA6206;
    padding: 10px 20px;
}

.tradeAdviceGridUl li:last-child 
{
    color: #000;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 20px;
    text-transform: capitalize;
}

.ta-text-color-lgt-org 
{
    color: #F35C5C;
}

.ta-text-color-lgt-org .mid-small-sepTA
{
    background-color: #F35C5C;
}

.ta-text-color-lgt-green 
{
    color: #67D6C4;
}

.ta-text-color-lgt-green .mid-small-sepTA
{
    background-color: #67D6C4;
}

.ta-text-color-lgt-gray 
{
    color: #696969;
}

.ta-text-color-lgt-gray .mid-small-sepTA
{
    background-color: #696969;
}

.two-divd-colored-right .flex-jc-wrapTA
{
    display: flex;
    justify-content: inherit;
    padding: 25px 10px;
    grid-column-gap: 0;
}
.side-details ul li {
    cursor: pointer;
  }
</style>
