<template>
  <v-app>
    <v-container>
      <v-row class="ds-header">
        <v-col>
          <div class="heading-2">Transaction History</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <share-card :share="selectedShare" :shouldRender="true" />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import ShareCard from '../components/ShareCard'
export default {
  components: {
    ShareCard
  },
  computed: {
    ...mapState(['selectedShare'])
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.heading-2 {
  @include heading_2_regular;
}
</style>
