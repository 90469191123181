<template>
  <div>
    <!-- <v-row>
      <v-col cols="10">
        <v-card v-if="share.trade_recommendation == 'Buy'" v-bind:class="['ds-card', share.share_category]" @click="onClick(share)">
          <v-row no-gutters>
            <v-col cols="2" class="ds-card-border-buy ds-card-title">{{
              share.purchase_serial
            }}</v-col>
            <v-col cols="10" class="ds-card-title ds-card-title-buy">{{
              share.company_name
            }}</v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="ds-card-border-buy"></v-col>
            <v-col class="ds-card-value left">Lower Band
              <v-divider class="ds-divider buy"></v-divider>
            </v-col>

            <v-col class="ds-card-value">Upper Band <v-divider class="ds-divider buy"></v-divider
            ></v-col>

            <v-col class="ds-card-value"
              >Year Low
              <v-divider class="ds-divider buy"></v-divider>
            </v-col>
            <v-col class="ds-card-value"
              >Year High
              <v-divider class="ds-divider buy"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="ds-card-border-buy"></v-col>
            <v-col class="ds-card-text left"> 
              <span v-bind:class="{ highlight: share.lastTransactionType == 'Sell'}" v-if="share.lastTransactionType == 'Sell'" title="Preferential Buying Recommendation">{{
              share.trading_rates.buy.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}</span>

            <span v-else >{{
              share.trading_rates.buy.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}</span>
            </v-col>
            <v-col class="ds-card-text">
            <span v-bind:class="{ highlight: share.lastTransactionType == 'Buy'}"  v-if="share.lastTransactionType == 'Buy'" title="Preferential Buying Recommendation">{{
              share.trading_rates.buy.max.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }} </span>

            <span v-else >{{
              share.trading_rates.buy.max.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }} </span>
            
            </v-col>
            <v-col class="ds-card-text">{{
              share.marketDataRef.year_low.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}</v-col>
            <v-col class="ds-card-text">{{
              share.marketDataRef.year_high.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}</v-col>
          </v-row>
        </v-card>
        <v-card v-if="share.trade_recommendation == 'Sell'" v-bind:class="['ds-card', share.share_category]" @click="onClick(share)">
          <v-row no-gutters>
            <v-col cols="2" class="ds-card-border-sell ds-card-title">{{share.saleshares}}</v-col>
            <v-col cols="10" class="ds-card-title ds-card-title-sell">{{
              share.company_name
            }}</v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="ds-card-border-sell"></v-col>
            <v-col class="ds-card-value left"
              >Target
              <v-divider class="ds-divider sell"></v-divider>
            </v-col>
            <v-col class="ds-card-value"
              >Year High
              <v-divider class="ds-divider sell"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="ds-card-border-sell"></v-col>
            <v-col class="ds-card-text left" v-bind:class="{ highlight: share.trading_rates.sell.min > share.marketDataRef.year_high }">{{
              share.trading_rates.sell.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}</v-col>
            <v-col class="ds-card-text" v-bind:class="{ highlight: share.trading_rates.sell.min < share.marketDataRef.year_high }">{{
              share.marketDataRef.year_high.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}</v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- @click="onClick(share)" -->
    <div class="dcb-wrap" v-if="share.trade_recommendation == 'Buy'">
					<ul class="tradeAdviceGridUl " :class="{'ta-a': share.share_category == 'A', 'ta-b':share.share_category == 'B', 'ta-c':share.share_category == 'C', 'ta-d':share.share_category == 'D', 'ta-e':share.share_category == 'E'}">
						<li class="th-ta1">00{{
              share.priority_order_buy
            }}</li>
						<li class="th-ta2">{{
              share.company_name
            }} <br />
            <span style="font-size:12px !important">Last Closing Rate  
            <span> 
              {{
                share.marketDataRef.last_traded_price.toLocaleString(
                  'en-IN',
                  {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  }
                )
              }} 
            </span>
            </span>
            </li>
					</ul>
					<ul class="tradeAdvice-white-grid">
						<li class="tr-ta1">
							<div class="flex-jc-wrapTA">
								<div class="table-detail-left ta-text-color-lgt-org">
									<div class="tradeA-top"> LOWER BAND  </div>
									<span class="mid-small-sepTA"> </span>
									<div class="tradeA-bottom" v-bind:class="{ highlight: share.lastTransactionType == 'Sell'}" v-if="share.lastTransactionType == 'Sell'" title="Preferential Buying Recommendation"> {{
              share.lower_band ? share.lower_band.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }) : share.trading_rates.buy.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>
									<div class="tradeA-bottom"  v-else> {{
              share.lower_band ? share.lower_band.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }) :share.trading_rates.buy.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>
								</div>
								<div class="table-detail-left ta-text-color-lgt-green">
									<div class="tradeA-top"> UPPER BAND  </div>
									<span class="mid-small-sepTA"> </span>
									<div class="tradeA-bottom" v-bind:class="{ highlight: share.lastTransactionType == 'Buy'}"  v-if="share.lastTransactionType == 'Buy'" title="Preferential Buying Recommendation"> {{
              share.upper_band ? share.upper_band.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }) : share.trading_rates.buy.max.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>
									<div class="tradeA-bottom" v-else> {{
              share.upper_band ? share.upper_band.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }) :share.trading_rates.buy.max.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>
								</div>
								<div class="table-detail-left ta-text-color-lgt-gray">
									<div class="tradeA-top"> YEARS LOW  </div>
									<span class="mid-small-sepTA"> </span>
									<div class="tradeA-bottom"> {{
              share.marketDataRef.year_low.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>
								</div>
								<div class="table-detail-left ta-text-color-lgt-gray">
									<div class="tradeA-top"> YEARS HIGH  </div>
									<span class="mid-small-sepTA"> </span>
									<div class="tradeA-bottom"> {{
              share.marketDataRef.year_high.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>
								</div>
							</div>
						</li>				
					</ul>					
				</div>

        
        <div class="dcb-wrap" v-if="share.trade_recommendation == 'Sell'">
					<ul class="tradeAdviceGridUl" :class="{'ta-a': share.share_category == 'A', 'ta-b':share.share_category == 'B', 'ta-c':share.share_category == 'C', 'ta-d':share.share_category == 'D', 'ta-e':share.share_category == 'E'}">
						<li class="th-ta1">00{{share.priority_order_sell}}</li>
						<li class="th-ta2">{{
              share.company_name
            }}<br />
            <span style="font-size:12px !important">
            Last Closing Rate  
            <span> 
              {{
                share.marketDataRef.last_traded_price.toLocaleString(
                  'en-IN',
                  {
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'INR'
                  }
                )
              }} 
            </span>
            </span>
            </li>
					</ul>
					<ul class="tradeAdvice-white-grid">
						<li class="tr-ta1">
							<div class="flex-jc-wrapTA">
								<div class="table-detail-left ta-text-color-lgt-org">
									<div class="tradeA-top"> Target  </div>
									<span class="mid-small-sepTA"> </span>
							
              
              <div class="tradeA-bottom" v-if = "share.upper_band" v-bind:class="{ highlight: share.upper_band > share.marketDataRef.year_high }"> {{
              share.upper_band?share.upper_band.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }):share.trading_rates.sell.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>

              <div class="tradeA-bottom" v-else v-bind:class="{ highlight: share.trading_rates.sell.min > share.marketDataRef.year_high }"> {{
              share.upper_band?share.upper_band.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              }):share.trading_rates.sell.min.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>


								</div>



								<div class="table-detail-left ta-text-color-lgt-green">
									<div class="tradeA-top"> Year High  </div>
									<span class="mid-small-sepTA"> </span>
									
                  
                  <div class="tradeA-bottom" v-if = "share.upper_band" v-bind:class="{ highlight: share.upper_band < share.marketDataRef.year_high }"> {{
              share.marketDataRef.year_high.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>

                  <div class="tradeA-bottom" v-else v-bind:class="{ highlight: share.trading_rates.sell.min < share.marketDataRef.year_high }"> {{
              share.marketDataRef.year_high.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
              })
            }}  </div>


								</div>

                
							</div>
						</li>				
					</ul>					
				</div>


  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RecommendationCard',
  data() {
    return {
      sellIndex: 1,
    }
  },
  props: {
    share: Object,
    buy: Boolean
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods:{
    rowSelected(shareId) {
      console.log(
        '🚀 ~ file: Home.vue ~ line 95 ~ rowSelected ~ event',
        shareId
      )
      // on click of share row, load trasactions based on share id
      // shareId is received through the event.
      this.selectedShareRow(shareId)
      // this.getTransactions(shareId);
    },
    ...mapActions(['deleteShare','updateActiveDialog', 'selectedShareRow', 'getTransactions']),
    onClick(id) {
        this.rowSelected(id)
        if(this.userProfile.role != 'Admin') {
          this.$router.push({ name: 'Transactions' }); 
        }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';


.A {
   .ds-card-title {
    @include heading_3_bold;
    padding: 1rem;
  }
  .ds-card-border-buy {
    background-color: $colors-brand-secondary-pink;
  }
  .ds-card-title-buy {
    background-color: $colors-brand-secondary-pink_light-7;
  }
  .ds-card-border-sell {
    background-color: $colors-brand-secondary-green;
  }
  .ds-card-title-sell {
    background-color: $colors-brand-secondary-green_light-7;
  }
  .ds-card-value {
    margin-top: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-card-text {
    margin-bottom: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-divider {
    max-width: 1.75rem;
    &.buy {
      border-color: $colors-brand-secondary-pink;
    }
    &.sell {
      border-color: $colors-brand-secondary-green;
    }
  }
}
.B {
  .ds-card-title {
    @include heading_3_bold;
    padding: 1rem;
  }
  .ds-card-border-buy {
    background-color: $colors-brand-secondary-orange;
  }
  .ds-card-title-buy {
    background-color: $colors-brand-secondary-orange_light-7;
  }
  .ds-card-border-sell {
    background-color: $colors-brand-secondary-orange;
  }
  .ds-card-title-sell {
    background-color: $colors-brand-secondary-orange_light-7;
  }
  .ds-card-value {
    margin-top: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-card-text {
    margin-bottom: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-divider {
    max-width: 1.75rem;
    &.buy {
      border-color: $colors-brand-secondary-orange;
    }
    &.sell {
      border-color: $colors-brand-secondary-orange;
    }
  }
}
.C {
  .ds-card-title {
    @include heading_3_bold;
    padding: 1rem;
  }
  .ds-card-border-buy {
    background-color: $colors-brand-secondary-yellow;
  }
  .ds-card-title-buy {
    background-color: $colors-brand-secondary-yellow_light-7;
  }
  .ds-card-border-sell {
    background-color: $colors-brand-secondary-yellow;
  }
  .ds-card-title-sell {
    background-color: $colors-brand-secondary-yellow_light-7;
  }
  .ds-card-value {
    margin-top: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-card-text {
    margin-bottom: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-divider {
    max-width: 1.75rem;
    &.buy {
      border-color: $colors-brand-secondary-yellow;
    }
    &.sell {
      border-color: $colors-brand-secondary-yellow;
    }
  }
}
.D {
  .ds-card-title {
    @include heading_3_bold;
    padding: 1rem;
  }
  .ds-card-border-buy {
    background-color: $colors-brand-secondary-blue;
  }
  .ds-card-title-buy {
    background-color: $colors-brand-secondary-blue_light-7;
  }
  .ds-card-border-sell {
    background-color: $colors-brand-secondary-blue;
  }
  .ds-card-title-sell {
    background-color: $colors-brand-secondary-blue_light-7;
  }
  .ds-card-value {
    margin-top: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-card-text {
    margin-bottom: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-divider {
    max-width: 1.75rem;
    &.buy {
      border-color: $colors-brand-secondary-blue
    }
    &.sell {
      border-color: $colors-brand-secondary-blue;
    }
  }
}
.E {
  .ds-card-title {
    @include heading_3_bold;
    padding: 1rem;
  }
  .ds-card-border-buy {
    background-color: $colors-brand-secondary-green;
  }
  .ds-card-title-buy {
    background-color: $colors-brand-secondary-green_light-7;
  }
  .ds-card-border-sell {
    background-color: $colors-brand-secondary-green;
  }
  .ds-card-title-sell {
    background-color: $colors-brand-secondary-green_light-7;
  }
  .ds-card-value {
    margin-top: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-card-text {
    margin-bottom: 1rem;
    &.left {
      margin-left: 1rem;
    }
  }
  .ds-divider {
    max-width: 1.75rem;
    &.buy {
      border-color: $colors-brand-secondary-green
    }
    &.sell {
      border-color: $colors-brand-secondary-green;
    }
  }
}
.highlight {
  background-color: #7557CC;
  padding:5px;
  color:#FFFFFF;
}
.dcb-wrap ul li {
    cursor: pointer;
}
</style>
